<template>
  <div>
    <div class="oferta__Section">
      <single-oferta
        :id="'box1'"
        :bg="'/assets/ZakresBgs/servers.jpg'"
        :heading="'Obsługa informatyczna firm'"
        :content="'Kompleksowa obsługa informatyczna firm, dostawa i montaż sprzętu komputerowego, wdrażanie nowych rozwiązań, zarządzanie istniejącą infrastrukturą, konfiguracja i administracja sieciami komputerowymi, serwerami, usuwanie awarii. Doradztwo w zakresie IT, szkolenia pracowników, optymalizacja procesów.'"
      ></single-oferta>
      <single-oferta
        :id="'box2'"
        :bg="'/assets/ZakresBgs/monitoring.jpg'"
        :heading="'Monitoring infrastruktury IT'"
        :content="'Świadczymy usługi na najwyższym poziomie dlatego też musimy zadbać o monitoring urządzeń oraz usług naszych klientów. Nasz system opiera się na dedykowanych rozwiązaniach. Wiemy z wyprzedzeniem o każdej awarii sprzętu oraz gdy brakuje zasobów. Dzięki takiemu rozwiązaniu wiemy jak optymalizować koszty na infrastrukturę oraz gdzie występują problemy.'"
      ></single-oferta>
      <single-oferta
        :id="'box3'"
        :bg="'/assets/ZakresBgs/rodo1111.png'"
        :heading="'Wdrażanie RODO'"
        :content="'We współpracy z inspektorami danych osobowych przygotowujemy jednostki by spełniały wymagania dotyczące zabezpieczeń fizycznych jak i programowych. Przeprowadzamy audyty sprzętu komputerowego, sieciowego oraz oprogramowania.'"
      ></single-oferta>
      <single-oferta
        :id="'box4'"
        :bg="'/assets/ZakresBgs/teleinformatyka.jpg'"
        :heading="'Budowa sieci informatycznych i teletechnicznych'"
        :content="'Wykonujemy sieci komputerowe w kategoriach 5e, 6, 6A. Sieci LAN, telefoniczne, światłowodowe. Wdrażamy sieci WIFI w domach i firmach. Stawiamy na optymalne rozwiązania w każdym budżecie. Administracja każdą infrastrukturą sieciową nie stanowi dla nas problemu.'"
      ></single-oferta>
    </div>
    <div class="navigation">
      <slot>
        <side-nav></side-nav>
      </slot>
    </div>
  </div>
</template>

<script>
import SingleOferta from './ofertaSection/ofertaBox.vue';
import SideNav from '../UI/sideNav.vue';
export default {
  components: {
    SingleOferta,
    SideNav,
  },
  data() {
    return{

    };
  },
  methods: {

  },
}
</script>

<style scoped>
.oferta__Section{
    top: 0;
    width: 100%;
    height: calc(100vh - 141px);
    transition: 0.5s all; 
    color: white;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth !important;
}
@media screen and (max-width:1180px){
  .oferta__Section{
    height: calc(100vh - 104px);
  }
}
.navigation{
  position: fixed;
  z-index: 322;
}
@media screen and (max-width:1180px){
  .navigation{
    position: fixed;
    height: calc(100vh - 104px);
  }
}
</style>