<template>
  <carousel :settings='settings' :breakpoints='breakpoints' :autoplay="2000" :wrapAround="true">
    <slide v-for="slide in slides" :key="slide">
      <img class="carousel__item" :src=slide.path alt="">
    </slide>

    <template #addons>
    </template>
  </carousel>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide } from 'vue3-carousel';

export default {
  name: 'App',
  components: {
    Carousel,
    Slide
  },
  data(){
    return{
      slides: [
        {
          name: 'adata',
          path: '/assets/partnerzy/adata.png'
        },
        {
          name: 'asus',
          path: '/assets/partnerzy/asus.png'
        },
        {
          name: 'cisco',
          path: '/assets/partnerzy/cisco.png'
        },
        {
          name: 'dell',
          path: '/assets/partnerzy/dell.png'
        },
        {
          name: 'dlink',
          path: '/assets/partnerzy/dlink.png'
        },
        {
          name: 'eset',
          path: '/assets/partnerzy/eset.png'
        },
        {
          name: 'intel',
          path: '/assets/partnerzy/intel.png'
        },
        {
          name: 'microsoft',
          path: '/assets/partnerzy/microsoft.png'
        },
        {
          name: 'mikrotik',
          path: '/assets/partnerzy/mikrotik.png'
        },
        {
          name: 'synology',
          path: '/assets/partnerzy/synology.jpg'
        },
        {
          name: 'ubiquiti',
          path: '/assets/partnerzy/ubiquiti.jpg'
        },
      ],
      settings: {
      itemsToShow: 1,
      snapAlign: 'center',
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 3.5,
          snapAlign: 'center',
        },
        // 1024 and up
        1024: {
          itemsToShow: 5,
          snapAlign: 'center',
        },
      },
    };
  },
};
</script>

<style scoped>
.carousel__slide > .carousel__item {
  transform: scale(1);
  opacity: 0.5;
  transition: 0.5s;
}
.carousel__slide--visible > .carousel__item {
  opacity: 1;
  transform: rotateY(0);
}
.carousel__slide--next > .carousel__item {
  transform: scale(0.9) translate(-10px);
}
.carousel__slide--prev > .carousel__item {
  transform: scale(0.9) translate(10px);
}
.carousel__slide--active > .carousel__item {
  transform: scale(1.3);
}
</style>