<template>
  <div class="button__Container">
    <h1 :style="{color: color}">{{ text }}</h1>
    <div :style="{width: sizeX, height: sizeY, backgroundColor: color}" class="button"></div>
  </div>
</template>


<script>
export default {
  props: ['text', 'color', 'sizeX', 'sizeY'],
}
</script>
<style scoped>
.button__Container{
  display: flex;
  justify-content: right;
  align-items: center;
  cursor: pointer;
  min-height: 20px;
}
h1{
  color: inherit;
  font-size: 0.9rem;
  font-weight: 300;
  transition: 0.3s all;
}
.button{
  background-color: #ffffff86;
  width: 20px;
  height: 20px;
  transition: 0.3s all;
  cursor: pointer;
  margin-left: 10px;
}
.button__Container:hover >.button{
  transform: scale(1.3);
  border: #ffffff solid 1px;
}
.button__Container:hover > h1{
  transform: translateX(-5%);
}
.button.active{
  transform: scale(1.3);
  border: black solid 1px;
}
@media screen and (max-width:780px){
  h1{
  color: inherit;
  font-size: 0.6rem;
  font-weight: 300;
  transition: 0.3s all;
}
}
</style>