<template>
  <div>
    <div class="main__Section">
      <home-section
        v-if="selectedBtn.id === 1"
        :h1="selectedBtn.h1"
        :h2="selectedBtn.h2"
        :imgSrc="selectedBtn.imgSrc"
        :link="selectedBtn.link"
      ></home-section>
      <home-section
        v-else-if="selectedBtn.id === 2"
        :h1="selectedBtn.h1"
        :h2="selectedBtn.h2"
        :imgSrc="selectedBtn.imgSrc"
        :link="selectedBtn.link"
      ></home-section>
      <home-section
        v-else-if="selectedBtn.id === 3"
        :h1="selectedBtn.h1"
        :h2="selectedBtn.h2"
        :imgSrc="selectedBtn.imgSrc"
        :link="selectedBtn.link"
      ></home-section>
      <home-section
        v-else-if="selectedBtn.id === 4"
        :h1="selectedBtn.h1"
        :h2="selectedBtn.h2"
        :imgSrc="selectedBtn.imgSrc"
        :link="selectedBtn.link"
      ></home-section>
      <div class="main__Section__Nav">
        <btn-btn @click="changeSlide(button)" v-for="button in buttons" :key="button.id"></btn-btn>
      </div>
    </div>
    <div class="partners__Section">
      <partner-section></partner-section>
    </div>
    <div class="footer">
      <the-footer></the-footer>
    </div>
  </div>
</template>

<script>
import HomeSection from './homeSection/homeSection.vue';
import PartnerSection from './homeSection/partnersSection.vue';
export default {
  components:{
    HomeSection,
    PartnerSection
  },
  data() {
    return {
      selectedBtn: {
        id: 1,
        h1: 'Outsourcing IT',
        h2: 'Kompleksowa obsługa informatyczna firm, dostawa i montaż sprzętu.',
        imgSrc: '/assets/Home/outsourcing.png',
        link: '#box1'
      },
      buttons: [
        {
          id: 1,
          h1: 'Outsourcing IT',
          h2: 'Kompleksowa obsługa informatyczna firm, dostawa i montaż sprzętu.',
          imgSrc: '/assets/Home/outsourcing.png',
          link: '#box1'
        },
        {
          id: 2,
          h1: 'Monitoring infrastruktury',
          h2: 'Wdrażanie profesjonalnych systemów monitorujących infrastrukturę sieciową. Niezawodne rozbudowane i zoptymalizowane moninitorowanie urządzeń i usług.',
          imgSrc: '/assets/Home/monitoringSieci.png',
          link: '#box2'
        },
        {
          id: 3,
          h1: 'Wdrażanie RODO',
          h2: 'Przygotowujemy jednostki by spełniały wymagania dotyczące zabezpieczeń fizycznych jak i programowych.',
          imgSrc: '/assets/Home/rodo2.png',
          link: '#box3'
        },
        {
          id: 4,
          h1: 'Servery fizyczne i wirtualne',
          h2: 'Nasze doświadczenie i kompetencje pozwalają na administracje rozwiązaniami chmurowimy, serwerami fizycznymi oraz środowiskiem opartym o wirtualizację.',
          imgSrc: '/assets/Home/cloud.png',
          link: '#box4'
        },
      ],
    };
  },
  methods: {
    changeSlide(button){
      this.selectedBtn = button;
    }
  },
}
</script>

<style scoped>

.main__Section{
  position: relative;
  overflow: hidden;
}
.main__Section__Nav{
  overflow: visible;
  position: absolute;
  bottom: 50px;
  left: calc(50% - 60px);
  display: flex;
  align-content: center;
  justify-content: space-around;
  width: 120px;
  height: 20px;
}
.partners__Section{
  background-color: white;
}

</style>