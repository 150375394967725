<template>
  <div class="main__Section__Content">
      <div class="heroDesc">
        <h1>{{ h1 }}</h1>
        <h2>{{ h2 }}</h2>
        <a :href="`/oferta/${link}`"><button>Więcej</button></a>
      </div>
      <div class="heroPhoto">
        <img :src="imgSrc" alt="">
      </div> 
    </div>
</template>

<script>
export default {
  props: ['h1', 'h2', 'imgSrc', 'link'],
}
</script>

<style scoped>
.main__Section__Content{
    top: 0;
    width: 100%;
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    color: white;
    padding: 0 4%;
}
.heroDesc{
    position: relative;
    width: 50%;
    padding: 1% 3%;
    overflow-x: hidden;
    animation: heroDesc 0.5s forwards;
    transform: scaleY(0%);
    animation-delay: 0.3s;
}
@keyframes heroDesc {
    0%{
        transform: scaleY(0%);
    }
    100%{
        transform: scaleY(100%);
    }
}
.heroDesc::before{
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 0.4rem;
    height: 100%;
    background-color: white;
    z-index: 1;
}
.heroDesc h1{
    font-size: 2.2rem;
    font-weight: 400;
    margin-bottom: 1rem;
    animation: h1h2 1.5s forwards;
    animation-delay: 0.5s;
    transform: translateX(-200%);
    
}
.heroDesc h2{
    font-size: 1.2rem;
    font-weight: 300;
    margin-bottom: 1rem;
    animation: h1h2 1.5s forwards;
    animation-delay: 0.7s;
    transform: translateX(-200%);
}
.heroDesc button{
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 5px;
    font-weight: 400;
    border: none;
    cursor: pointer;
    background-color: white;
    box-shadow: 0px 0px 30px 2px rgb(0 0 0 / 25%);
    animation: h1h2 1.5s forwards;
    animation-delay: 0.9s;
    transform: translateX(-200%);
    transition: background-color 0.3s;
}
.heroDesc button:hover{
    background-color: rgb(206, 202, 202);
}
@keyframes h1h2 {
    0%{
        transform: translateX(-200%);
    }
    100%{
        transform: translateX(0%);
    }
}
.heroPhoto img{
    width: 500px;
    transform: translateX(200%);
    animation: h1h3 1s forwards;
}
@keyframes h1h3 {
    0%{
        transform: translateX(200%);
    }
    100%{
        transform: translateX(0%);
    }
}
@media screen and (max-width:870px){
    .main__Section__Content{
        justify-content: space;
        align-content: center;
        margin-bottom: 10%;
    }
    .heroDesc h1{
    font-size: 1.5rem;
    font-weight: 400;
    
    }
    .heroDesc h2{
        font-size: 1rem;
        font-weight: 300;
    }
    .heroDesc{
        padding: 1% 10%;
        margin-bottom: 10%;
        width: 90%;
    }
    .heroPhoto img{
    width: 300px;
    }
}
</style>